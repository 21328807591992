import Vue from 'vue'
import VueRouter from 'vue-router'
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/home',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: '主控面板',
        breadcrumb: [
          {
            text: '首页',
            active: true,
          },
        ],
      },
    },
    {
      path: '/boardcast_device',
      name: 'boardcast_device',
      component: () => import('@/views/data/build.vue'),
      meta: {
        pageTitle: '广播模块',
        breadcrumb: [
          {
            text: '广播管理',
            active: true,
          },
        ],
      },
    },
    {
 
      path: '/boardcast_voice',
      name: 'boardcast_voice',
      component: () => import('@/views/data/house.vue'),
      meta: {
        pageTitle: '广播模块',
        breadcrumb: [
          {
            text: '媒体管理',
            active: true,
          },
        ],
      },
    },
    {
      path: '/boardcast_plan',
      name: 'boardcast_plan',
      component: () => import('@/views/news/menu.vue'),
      meta: {
        pageTitle: '广播模块',
        breadcrumb: [
          {
            text: '计划管理',
            active: true,
          },
        ],
      },
    },
    {
      path: '/lamp_device',
      name: 'lamp_device',
      component: () => import('@/views/data/people.vue'),
      meta: {
        pageTitle: '路灯模块',
        breadcrumb: [
          {
            text: '路灯管理',
            active: true,
          },
        ],
      },
    },
    {
      path: '/regular_time',
      name: 'regular_time',
      component: () => import('@/views/data/regular.vue'),
      meta: {
        pageTitle: '路灯模块',
        breadcrumb: [
          {
            text: '定时管理',
            active: true,
          },
        ],
      },
    },
    {
      path: '/lamp_gateway',
      name: 'lamp_gateway',
      component: () => import('@/views/data/gateway.vue'),
      meta: {
        pageTitle: '路灯模块',
        breadcrumb: [
          {
            text: '网关管理',
            active: true,
          },
        ],
      },
    },
    {
      path: '/news-menu',
      name: 'news-menu',
      component: () => import('@/views/news/menu.vue'),
      meta: {
        pageTitle: '文章模块',
        breadcrumb: [
          {
            text: '栏目管理',
            active: true,
          },
        ],
      },
    },
    {
      path: '/news-manage',
      name: 'news-manage',
      component: () => import('@/views/news/manage.vue'),
      meta: {
        pageTitle: '文章模块',
        breadcrumb: [
          {
            text: '文章管理',
            active: true,
          },
        ],
      },
    },
    {
      path: '/system-admin',
      name: 'system-admin',
      component: () => import('@/views/system/admin.vue'),
      meta: {
        pageTitle: '系统设置',
        breadcrumb: [
          {
            text: '用户管理',
            active: true,
          },
        ],
      },
    },
    {
      path: '/system-admin',
      name: 'system-admin',
      component: () => import('@/views/system/admin.vue'),
      meta: {
        pageTitle: '系统设置',
        breadcrumb: [
          {
            text: '用户管理',
            active: true,
          },
        ],
      },
    },
    {
      path: '/system-menu',
      name: 'system-menu',
      component: () => import('@/views/system/menu.vue'),
      meta: {
        pageTitle: '系统设置',
        breadcrumb: [
          {
            text: '菜单管理',
            active: true,
          },
        ],
      },
    },
    {
      path: '/system-role',
      name: 'system-role',
      component: () => import('@/views/system/role.vue'),
      meta: {
        //layout: 'full',全屏
        pageTitle: '系统设置',
        breadcrumb: [
          {
            text: '角色管理',
            active: true,
          },
        ],
      },
    },
    {
      path: '/system-logs',
      name: 'system-logs',
      component: () => import('@/views/system/logs.vue'),
      meta: {
        pageTitle: '系统设置',
        breadcrumb: [
          {
            text: '操作日志',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/',
      name: 'model',
      component: () => import('@/views/model/index.vue'),
      meta: {
        layout: 'full',
        rand: Math.random()
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})


//*******以下为添加内容*******
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
//******以上为添加内容*********


router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
//  console.log(to,"路由");
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    //if (!isLoggedIn) return next({ name: 'login' })

    // If logged in => not authorized
    //return next({ name: 'login' })
  }

  // Redirect if logged in
  // if (to.meta.redirectIfLoggedIn && isLoggedIn) {
  //   const userData = getUserData()
  //   next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  // }
// 
  return next()
})
 

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
