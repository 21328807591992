import Vue from 'vue'
// axios
import axios from 'axios'
import qs from 'qs'
import router from '@/router';
 

 let token =  localStorage.getItem("accessToken");
//请求拦截器：在发请求之前，请求拦截器可以检测到，可以在请求发出去之前做一些事情

const axiosIns = axios.create({
  // baseURL: 'https://adm.cdlife.vip/wlwpt/',
  baseURL: 'http://yunhu.njqianyan.cn/wlwpt',
  timeout: 1000*3600,
  headers: {'Authorization':token }
})

axiosIns.interceptors.request.use((config) => {
  //config:配置对象，对象里面有一个属性很重要，headers请求头
  //console.log('请求拦截器执行', config);
  
  config.data = qs.stringify(config.data) 
  //nprogress.start(); //进度条开始
  return config;  //检查完再把东西还给人家
});
//3.响应拦截器
axiosIns.interceptors.response.use(
  (res) => {
      if(res.data.code=="40001"||res.data.code=="40003"){//登录失效
        router.push({ name:"login", path:"/login" })
        return
      }
      return res.data;
  },
  (error) => {
      console.log(error);
      return Promise.reject(error);
  }
);
 

Vue.prototype.$http = axiosIns

export default axiosIns
