import { $themeConfig } from '@themeConfig'



let isCollapsed = false
isCollapsed = localStorage.getItem('isCollapsed')
if(isCollapsed=="false"){
  isCollapsed=false
}else{
  isCollapsed=true
}
console.log(isCollapsed,"导航伸缩");

export default {
  namespaced: true,
  state: {
    //isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
    isVerticalMenuCollapsed: isCollapsed
  },
  getters: {},
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val
    },
  },
  actions: {},
}
