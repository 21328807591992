import { Ability } from '@casl/ability'
import { initialAbility } from './config'

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
 
// const userData = '';
// try {
    const userData = JSON.parse(localStorage.getItem('userData'))
    //console.log(userData,"userData.ability1");
// } catch(error) {
//     console.log(error)
// }

//console.log(userData.ability,"userData.ability2");

const existingAbility = userData ? userData.ability : null
 
export default new Ability(existingAbility || initialAbility)
